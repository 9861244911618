export default {
  apply_org_by_phone_mundefined_num: undefined,
  repay_succ_mundefined_money: undefined,
  apply_by_id_m1_num: undefined,
  debt_org_mundefined_num: undefined,
  repay_fail_mundefined_num: undefined,
  repay_fail_m1_money: undefined,
  repay_succ_d7_money: undefined,
  repay_succ_m1_money: undefined,
  repay_succ_m6_money: undefined,
  debt_www_total_num: undefined,
  apply_by_phone_m1_num: undefined,
  apply_by_id_d7_num: undefined,
  apply_org_by_id_m6_num: undefined,
  apply_by_phone_d7_num: undefined,
  apply_org_by_id_m1_num: undefined,
  apply_org_by_phone_m6_num: undefined,
  debt_org_total_num: undefined,
  repay_fail_d7_money: undefined,
  apply_by_id_mundefined_num: undefined,
  repay_succ_m6_num: undefined,
  debt_shopping_total_num: undefined,
  repay_fail_m1_num: undefined,
  repay_succ_m1_num: undefined,
  ovdure_org_num: undefined,
  apply_org_by_phone_m1_num: undefined,
  debt_org_m1_num: undefined,
  debt_first_distance_now: undefined,
  repay_succ_lately_num: undefined,
  repay_fail_m6_num: undefined,
  debt_org_m6_num: undefined,
  apply_by_phone_mundefined_num: undefined,
  repay_fail_d7_num: undefined,
  debt_org_d7_num: undefined,
  apply_org_by_phone_d7_num: undefined,
  debt_settled_num: undefined,
  ovdure_org_money: undefined,
  ovdure_flag: undefined,
  apply_org_by_id_mundefined_num: undefined,
  repay_succ_mundefined_num: undefined,
  apply_by_id_m6_num: undefined,
  repay_succ_d7_num: undefined,
  repay_fail_mundefined_money: undefined,
  repay_fail_m6_money: undefined,
  apply_by_phone_m6_num: undefined,
  apply_org_by_id_d7_num: undefined,
  buildData:[],
  buildUUData:[],
  date: ""
};

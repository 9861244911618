export default {
  tap001:'名下常⽤卡数量_1*2*3*4_(0,2)*[2,3)*[3,Inf)*(0,3]',
  tap002:'名下常⽤⼿机号数量_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap003:'最近⼀次成功交易时间(年⽉⽇)__',
  tap004:'最近⼀次成功交易⾦额_1*2*3*4_(0,100)*[100,500)*[500,2000)*[2000,Inf)',
  tap005:'最近⼀次成功交易⼀级⾏业_SHOP*TRV*FIN*EDU*ENT*OTH_⽣活购物类*出⾏旅游类*⾦融及实物投资类*教育培训类*娱乐服务类*其他',
  tap006:'初次⽹络交易时间(年⽉)__',
  tap007:'单⽇最⾼交易额_1*2*3*4*5_(0,500)*[500,1000)*[1000,2000)*[2000,2500)*[2500,Inf)',
  tap008:'平均单⽇交易额_1*2*3_(0,100)*[100,1700)*[1700,Inf)',
  tap009:'最⾼单⽉交易额_1*2*3*4_(0,1000)*[1000,2000)*[2000,7000)*[7000,Inf)',
  tap010:'平均单⽉交易额_1*3*4_(0,500)*[1000,3000)*[3000,Inf)',
  tap011:'平均单⽉交易笔数_1*2*3*4_(0,1)*[1,4)*[4,10)*[10,Inf)',
  tap012:'历史交易订单数_1*2*3*4*5_(0,5)*[5,45)*[45,75)*[75,105)*[105,Inf)',
  tap013:'历史交易总⾦额_1*2*3*4*5*6_(0,2000)*[2000,8000)*[8000,30000)*[30000,50000)*[50000,128000)*[128000,Inf)',
  tap014:'过去⼀年发⽣交易⽉份数_1*2*3_(0,1)*[1,2)*[2,10)',
  tap015:'⽀付成功率：⽀付成功次数/(⽀付成功次数+⽀付失败次数)__',
  tap016:'交易笔数最多的⾏业分类_SHOP_⽣活购物类',
  tap017:'交易⾦额最⾼的⾏业分类_SHOP_⽣活购物类',
  tap018:'⽣活购物类消费笔数最多的⾦额区间_A*B*C*D*E*F_(10000,Inf)*(5000,10000]*(1000,5000]*(200,1000]*(50,200]*[0,50]',
  tap019:'出⾏旅游类消费笔数最多的⾦额区间_A_(10000,Inf)',
  tap020:'⾦融及实物投资类消费笔数最多的⾦额区间_A_(10000,Inf)',
  tap021:'教育培训类消费笔数最多的⾦额区间_A_(10000,Inf)',
  tap022:'娱乐服务类消费笔数最多的⾦额区间_A_(10000,Inf)',
  tap023:'其他类消费笔数最多的⾦额区间_A_(10000,Inf)',
  tap024:'保险类交易⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap025:'房屋租赁类交易⾦额_1_(0,300]',
  tap026:'航旅类交易⾦额_1_(0,300]',
  tap027:'互⾦平台交易⾦额_1*2*3*4_(0,2000)*[2000,10000)*[10000,88000)*[88000,Inf)',
  tap028:'消费能⼒评分(计算过去12个⽉的消费⾏为评分，200分为过去⼀年⽆成功交易，240分~900分为有成功交易评分)__',
  tap029:'⽤户标签(详见5.5⽤户标签表)_A*B*C*D*E*F*G*H_低价值⽇间交易⽤户*⾼频低价值⽇间交易⽤户*低频⾼价值⽇间交易⽤户*⾼价值⽇间交易⽤户*低价值⾮⽇间交易⽤户*⾼频低价值⾮⽇间交易⽤户*低频⾼价值⾮⽇间交易⽤户*低价值⽇间交易⽤户',
  tap030:'个⼈消费偏好:0-⽆成功交易其他_SHOP_⽣活购物类',
  tap031:'近3个⽉交易⾦额_1*3*4_(0,1000)*[2000,12000)*[12000,Inf)',
  tap032:'近6个⽉交易⾦额_1*2*3*4_(0,2000)*[2000,12000)*[12000,30000)*[30000,Inf)',
  tap033:'近12个⽉交易⾦额_1*3*4*5_(0,2000)*[10000,22000)*[22000,98000)*[98000,Inf)',
  tap034:'近3个⽉交易笔数_1*2*3*4_(0,1)*[1,14)*[14,26)*[26,Inf)',
  tap035:'近6个⽉交易笔数_1*2*3*4_(0,1)*[1,20)*[20,45)*[45,Inf)',
  tap036:'近12个⽉交易笔数_1*2*3*4_(0,2)*[2,24)*[24,82)*[82,Inf)',
  tap037:'近3个⽉发⽣交易的商户数_1*3*4_(0,1)*[2,11)*[11,Inf)',
  tap038:'近3个⽉发⽣交易的⼀级⾏业数_1*3_(0,1)*[2,Inf)',
  tap039:'近3个⽉发⽣交易的⼆级⾏业数_1_(0,1)',
  tap040:'近6个⽉发⽣交易的商户数_1*2*3*4_(0,1)*[1,9)*[9,14)*[14,Inf)',
  tap041:'近6个⽉发⽣交易的⼀级⾏业数_1_(0,1)',
  tap042:'近6个⽉发⽣交易的⼆级⾏业数_1_(0,1)',
  tap043:'近12个⽉发⽣交易的商户数_1*3*4_(0,1)*[9,13)*[13,Inf)',
  tap044:'近12个⽉发⽣交易的⼀级⾏业数_1_(0,1)',
  tap045:'近12个⽉发⽣交易的⼆级⾏业数_1_(0,1)',
  tap046:'近12个⽉在⽣活购物类交易的商户数_1*2_(0,1)*[1,Inf)',
  tap047:'近12个⽉在出⾏旅游类交易的商户数_1_(0,1)',
  tap048:'近12个⽉在⾦融及实物投资类交易的商户数_1*2*3*4_(0,1)*[1,8)*[8,12)*[12,Inf)',
  tap049:'近12个⽉在教育培训类交易的商户数_1_(0,3]',
  tap050:'近12个⽉在娱乐服务类交易的商户数_1_(0,3]',
  tap051:'近12个⽉在其他交易的商户数_1_(0,1)',
  tap052:'近3个⽉最⾼单笔交易⾦额_1*2*4*5_(0,400)*[400,1000)*[2000,5000)*[5000,Inf)',
  tap053:'近6个⽉最⾼单笔交易⾦额_1*3_(0,400)*[1000,Inf)',
  tap054:'近12个⽉最⾼单笔交易⾦额_1*4*5_(0,400)*[2000,3000)*[3000,Inf)',
  tap055:'近12个⽉在⽣活购物类的最⾼单笔交易⾦额_1_(0,300]',
  tap056:'近12个⽉在出⾏旅游类的最⾼单笔交易⾦额_1_(0,300]',
  tap057:'近12个⽉在⾦融及实物投资类的最⾼单笔交易⾦额_1*4*5_(0,400)*[2000,4000)*[4000,Inf)',
  tap058:'近12个⽉在教育培训类的最⾼单笔交易⾦额_1_(0,300]',
  tap059:'近12个⽉在娱乐服务类的最⾼单笔交易⾦额_1_(0,300]',
  tap060:'近12个⽉在其他的最⾼单笔交易⾦额_1_(0,300]',
  tap061:'近12个⽉平均单笔交易⾦额_1*2*3*4_(0,100)*[100,1300)*[1300,1600)*[1600,Inf)',
  tap062:'近12个⽉在⽣活购物类的平均单笔交易⾦额_1_(0,300]',
  tap063:'近12个⽉在出⾏旅游类的平均单笔交易⾦额_1_(0,300]',
  tap064:'近12个⽉在⾦融及实物投资类的平均单笔交易⾦额_1*2*3*4_(0,100)*[100,1500)*[1500,1700)*[1700,Inf)',
  tap065:'近12个⽉在教育培训类的平均单笔交易⾦额_1_(0,300]',
  tap066:'近12个⽉在娱乐服务类的平均单笔交易⾦额_1_(0,300]',
  tap067:'近12个⽉在其他的平均单笔交易⾦额_1_(0,300]',
  tap068:'近6个⽉在⽣活购物类交易的⾦额_1_(0,300]',
  tap069:'近6个⽉在出⾏旅游类交易的⾦额_1_(0,300]',
  tap070:'近6个⽉在⾦融及实物投资类交易的⾦额_1*3_(0,1000)*[2000,30000)',
  tap071:'近6个⽉在教育培训类交易的⾦额_1_(0,300]',
  tap072:'近6个⽉在娱乐服务类交易的⾦额_1_(0,300]',
  tap073:'近6个⽉在其他交易的⾦额_1_(0,300]',
  tap074:'近6个⽉在⽣活购物类交易的笔数_1*12*13_(0,3]*(500,1000]*(1000,Inf)',
  tap075:'近6个⽉在出⾏旅游类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap076:'近6个⽉在⾦融及实物投资类交易的笔数_1*2*3*4_(0,1)*[1,14)*[14,21)*[21,Inf)',
  tap077:'近6个⽉在教育培训类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap078:'近6个⽉在娱乐服务类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap079:'近6个⽉在其他交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap080:'近12个⽉在⽣活购物类交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap081:'近12个⽉在出⾏旅游类交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap082:'近12个⽉在⾦融及实物投资类交易的⾦额_1*2*3*4_(0,2000)*[2000,10000)*[10000,88000)*[88000,Inf)',
  tap083:'近12个⽉在教育培训类交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap084:'近12个⽉在娱乐服务类交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap085:'近12个⽉在其他交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap086:'近12个⽉在⽣活购物类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap087:'近12个⽉在出⾏旅游类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap088:'近12个⽉在⾦融及实物投资类交易的笔数_1*2*3*4_(0,2)*[2,16)*[16,28)*[28,Inf)',
  tap089:'近12个⽉在教育培训类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap090:'近12个⽉在娱乐服务类交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap091:'近12个⽉在其他交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap092:'近12个⽉在⼯作⽇⼯作时段交易的⾦额_1*2*3*4*5_(0,1000)*[1000,8000)*[8000,16000)*[16000,56000)*[56000,Inf)',
  tap093:'近12个⽉在⼯作⽇⾮⼯作时段交易的⾦额_1*2*3*4_(0,500)*[500,1000)*[1000,16000)*[16000,Inf)',
  tap094:'近12个⽉在⾮⼯作⽇交易的⾦额_1*2*3*4*5_(0,500)*[500,1000)*[1000,3500)*[3500,25000)*[25000,Inf)',
  tap095:'近12个⽉在⼯作⽇⼯作时段交易的笔数_1*2*3*4_(0,1)*[1,12)*[12,16)*[16,Inf)',
  tap096:'近12个⽉在⼯作⽇⾮⼯作时段交易的笔数_1*2*3*4*5_(0,1)*[1,2)*[2,4)*[4,15)*[15,Inf)',
  tap097:'近12个⽉在⾮⼯作⽇交易的笔数_1*2*3*4*5_(0,1)*[1,4)*[4,9)*[9,24)*[24,Inf)',
  tap098:'近6个⽉使⽤借记卡交易的⾦额_1*2*3_(0,1000)*[1000,30000)*[30000,Inf)',
  tap099:'近6个⽉使⽤贷记卡交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap100:'近12个⽉使⽤借记卡交易的⾦额_1*2*3*4*5_(0,2000)*[2000,10000)*[10000,32000)*[32000,90000)*[90000,Inf)',
  tap101:'近12个⽉使⽤贷记卡交易的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap102:'近6个⽉使⽤借记卡交易的笔数_1*2*3*4_(0,1)*[1,21)*[21,45)*[45,Inf)',
  tap103:'近6个⽉使⽤贷记卡交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap104:'近12个⽉使⽤借记卡交易的笔数_1*2*3*4_(0,2)*[2,24)*[24,78)*[78,Inf)',
  tap105:'近12个⽉使⽤贷记卡交易的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap106:'近12个⽉使⽤借记卡的最⾼单笔交易⾦额_1*2*3*4*5_(0,400)*[400,1000)*[1000,2000)*[2000,4000)*[4000,Inf)',
  tap107:'近12个⽉使⽤贷记卡的最⾼单笔交易⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap108:'近12个⽉消费⾦额在[0,50]的笔数_1*2*3*4_(0,1)*[1,2)*[2,4)*[4,Inf)',
  tap109:'近12个⽉消费⾦额在(50,200]的笔数_1*2*3*4*5*6_(0,1)*[1,2)*[2,3)*[3,4)*[4,8)*[8,Inf)',
  tap110:'近12个⽉消费⾦额在(200,1000]的笔数_1*2*3*4*5_(0,1)*[1,2)*[2,4)*[4,12)*[12,Inf)',
  tap111:'近12个⽉消费⾦额在(1000,5000]的笔数_1*2*3*4_(0,1)*[1,3)*[3,4)*[4,Inf)',
  tap112:'近12个⽉消费⾦额在(5000,10000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap113:'近12个⽉消费⾦额在10000+的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap114:'近12个⽉使⽤借记卡在⽣活购物类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap115:'近12个⽉使⽤借记卡在出⾏旅游类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap116:'近12个⽉使⽤借记卡在⾦融及实物投资类消费的⾦额_1*2*3*4_(0,2000)*[2000,10000)*[10000,88000)*[88000,Inf)',
  tap117:'近12个⽉使⽤借记卡在教育培训类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap118:'近12个⽉使⽤借记卡在娱乐服务类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap119:'近12个⽉使⽤借记卡在其他消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap120:'近12个⽉使⽤贷记卡在⽣活购物类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap121:'近12个⽉使⽤贷记卡在出⾏旅游类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap122:'近12个⽉使⽤贷记卡在⾦融及实物投资类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap123:'近12个⽉使⽤贷记卡在教育培训类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap124:'近12个⽉使⽤贷记卡在娱乐服务类消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap125:'近12个⽉使⽤贷记卡在其他消费的⾦额_1*2*3*4*5*6*7*8*9*10*11*12_(0,300]*(300,500]*(500,1000]*(1000,1500]*(1500,2000]*(2000,5000]*(5000,10000]*(10000,30000]*(30000,50000]*(50000,100000]*(100000,200000]*(200000,Inf)',
  tap126:'近12个⽉在⽣活购物类交易的⾦额在[0,50]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap127:'近12个⽉在⽣活购物类交易的⾦额在(50,200]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap128:'近12个⽉在⽣活购物类交易的⾦额在(200,1000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap129:'近12个⽉在⽣活购物类交易的⾦额在(1000,5000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap130:'近12个⽉在⽣活购物类交易的⾦额在(5000,10000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap131:'近12个⽉在⽣活购物类交易的⾦额在10000+的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap132:'近12个⽉在出⾏旅游类交易的⾦额在[0,50]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap133:'近12个⽉在出⾏旅游类交易的⾦额在(50,200]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap134:'近12个⽉在出⾏旅游类交易的⾦额在(200,1000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap135:'近12个⽉在出⾏旅游类交易的⾦额在(1000,5000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap136:'近12个⽉在出⾏旅游类交易的⾦额在(5000,10000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap137:'近12个⽉在出⾏旅游类交易的⾦额在10000+的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap138:'近12个⽉在⾦融及实物投资类交易的⾦额在[0,50]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap139:'近12个⽉在⾦融及实物投资类交易的⾦额在(50,200]的笔数_1*2*3*4_(0,3)*[3,5)*[5,8)*[8,Inf)',
  tap140:'近12个⽉在⾦融及实物投资类交易的⾦额在(200,1000]的笔数_1*2*3*4*5_(0,1)*[1,2)*[2,4)*[4,12)*[12,Inf)',
  tap141:'近12个⽉在⾦融及实物投资类交易的⾦额在(1000,5000]的笔数_1*2*3*4_(0,1)*[1,3)*[3,14)*[14,Inf)',
  tap142:'近12个⽉在⾦融及实物投资类交易的⾦额在(5000,10000]的笔数_1*2_(0,1)*[1,Inf)',
  tap143:'近12个⽉在⾦融及实物投资类交易的⾦额在10000+的笔数_1*2*3*4*5_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap144:'近12个⽉在教育培训类交易的⾦额在[0,50]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap145:'近12个⽉在教育培训类交易的⾦额在(50,200]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap146:'近12个⽉在教育培训类交易的⾦额在(200,1000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap147:'近12个⽉在教育培训类交易的⾦额在(1000,5000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap148:'近12个⽉在教育培训类交易的⾦额在(5000,10000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap149:'近12个⽉在教育培训类交易的⾦额在10000+的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap150:'近12个⽉在娱乐服务类交易的⾦额在[0,50]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap151:'近12个⽉在娱乐服务类交易的⾦额在(50,200]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap152:'近12个⽉在娱乐服务类交易的⾦额在(200,1000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap153:'近12个⽉在娱乐服务类交易的⾦额在(1000,5000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap154:'近12个⽉在娱乐服务类交易的⾦额在(5000,10000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap155:'近12个⽉在娱乐服务类交易的⾦额在10000+的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap156:'近12个⽉在其他交易的⾦额在[0,50]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap157:'近12个⽉在其他交易的⾦额在(50,200]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap158:'近12个⽉在其他交易的⾦额在(200,1000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap159:'近12个⽉在其他交易的⾦额在(1000,5000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap160:'近12个⽉在其他交易的⾦额在(5000,10000]的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
  tap161:'近12个⽉在其他交易的⾦额在10000+的笔数_1*2*3*4*5*6*7*8*9*10*11*12*13_(0,3]*(3,5]*(5,8]*(8,10]*(10,15]*(15,20]*(20,30]*(30,50]*(50,100]*(100,200]*(200,500]*(500,1000]*(1000,Inf)',
}



import service from "../../api";
import stateData from "./state";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setObject(state, data = {}) {
      console.log(JSON.stringify(data))
      for (let key in data) {

        state[key] = data[key];
      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    // 全景雷达信息
    getObject({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("setObject", {
        ...stateData
      });
      commit("userReport/setLoading", true, {
        root: true
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'OBJECT')
        .then(res => {
          commit("setObject", res.data.data.data.result.respBody.data.data.result_detail || {});
          commit("setProvider", res.provider || "");
          commit("setRefreshDate", res.date),
            commit("userReport/setLoading", false, {
              root: true
            });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters
};

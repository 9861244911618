export default {
    debt_amount:"-",
    current_order_count:"-",
    order_count:"-",
    member_count:"-",
    current_order_lend_amt:"-",
    current_order_amt:"-",
    current_org_count:"-",
    debt_detail:[],
    totaldebt_detail:[],
    date: ""
};

export default {

//名下常⽤卡数量
// 名下常⽤⼿机号数量
// 最近⼀次成功交易时间(年⽉⽇)
// 最近⼀次成功交易⾦额
// 最近⼀次成功交易⼀级⾏业
// 初次⽹络交易时间(年⽉)
// 单⽇最⾼交易额
// 平均单⽇交易额
// 最⾼单⽉交易额
// 平均单⽉交易额
// 平均单⽉交易笔数
// 历史交易订单数
// 历史交易总⾦额
// 过去⼀年发⽣交易⽉份数
// ⽀付成功率：⽀付成功次数/(⽀付成功次数+⽀付失败次数)
// 交易笔数最多的⾏业分类
// 交易⾦额最⾼的⾏业分类
// ⽣活购物类消费笔数最多的⾦额区间
// 出⾏旅游类消费笔数最多的⾦额区间
// ⾦融及实物投资类消费笔数最多的⾦额区间
// 教育培训类消费笔数最多的⾦额区间
// 娱乐服务类消费笔数最多的⾦额区间
// 其他类消费笔数最多的⾦额区间
// 保险类交易⾦额
// 房屋租赁类交易⾦额
// 航旅类交易⾦额
// 互⾦平台交易⾦额


// 基本信息
  baseInfo: state => {

    let tap001 = state.result_detail.tap001 || "-";
    let tap002 = state.result_detail.tap002 || "-";
    let tap003 = state.result_detail.tap003 || "-";
    let tap004 = state.result_detail.tap004 || "-";
    let tap005 = state.result_detail.tap005 || "-";
    let tap006 = state.result_detail.tap006 || "-";
    let tap007 = state.result_detail.tap007 || "-";
    let tap008 = state.result_detail.tap008 || "-";
    let tap009 = state.result_detail.tap009 || "-";
    let tap010 = state.result_detail.tap010 || "-";
    let tap011 = state.result_detail.tap011 || "-";
    let tap012 = state.result_detail.tap012 || "-";
    let tap013 = state.result_detail.tap013 || "-";
    let tap014 = state.result_detail.tap014 || "-";
    let tap015 = state.result_detail.tap015 || "-";
    let tap016 = state.result_detail.tap016 || "-";
    let tap017 = state.result_detail.tap017 || "-";
    let tap018 = state.result_detail.tap018 || "-";
    let tap019 = state.result_detail.tap019 || "-";
    let tap020 = state.result_detail.tap020 || "-";
    let tap021 = state.result_detail.tap021 || "-";
    let tap022 = state.result_detail.tap022 || "-";
    let tap023 = state.result_detail.tap023 || "-";
    let tap024 = state.result_detail.tap024 || "-";
    let tap025 = state.result_detail.tap025 || "-";
    let tap026 = state.result_detail.tap026 || "-";
    let tap027 = state.result_detail.tap027 || "-";
    let tap028 = state.result_detail.tap028 || "-";
    let tap029 = state.result_detail.tap029 || "-";
    let tap030 = state.result_detail.tap030 || "-";

    return [
      {name: "名下常用卡数量",value: tap001},
      {name: "名下常用手机号数量",value: tap002},
      {name: "最近一次成功交易时间",value: tap003},
      {name: "最近一次成功交易金额",value: tap004},
      {name: "最近一次成功交易一级行业",value: tap005},
      {name: "首次网络交易时间",value: tap006},
      {name: "单日最高交易额",value: tap007},
      {name: "平均单日交易额",value: tap008},
     {name: "最高单月交易额",value: tap009},
      {name: "平均单月交易额",value: tap010},
      {name: "平均单月交易笔数",value: tap011},
      {name: "历史交易订单数",value: tap012},
      {name: "历史交易总金额",value: tap013},
      {name: "过去一年发生交易月份数",value: tap014},
      {name: "支付成功率",value: tap015},
      {name: "交易笔数最多的行业分类",value: tap016},
      {name: "交易金额最多的行业分类",value: tap017},
      {name: "消费笔数最多的金额区间",value: tap018},
      {name: "消费金额最多的金额区间",value: tap019},
      {name: "保险类交易金额",value: tap020},
      {name: "房屋租赁类交易金额",value: tap021},
      {name: "航旅类交易金额",value: tap022},
      {name: "互金平台交易金额",value: tap023},
      {name: "其他类交易金额",value: tap024},
      {name: "房屋租赁类交易⾦额",value: tap025},
      {name: "航旅类交易⾦额",value: tap026},
      {name: "互⾦平台交易⾦额",value: tap027},
      {name: "消费能⼒评分",value: tap028},
      {name: "⽤户标签",value: tap029},
      {name: "个⼈消费偏好",value: tap030},

    ];
  },
  /**
   * tap031	近3个⽉交易⾦额
   tap032	近6个⽉交易⾦额
   tap033	近12个⽉交易⾦额
   tap034	近3个⽉交易笔数
   tap035	近6个⽉交易笔数
   tap036	近12个⽉交易笔数
   tap037	近3个⽉发⽣交易的商户数
   tap038	近3个⽉发⽣交易的⼀级⾏业数
   tap039	近3个⽉发⽣交易的⼆级⾏业数
   tap040	近6个⽉发⽣交易的商户数
   tap041	近6个⽉发⽣交易的⼀级⾏业数
   tap042	近6个⽉发⽣交易的⼆级⾏业数
   tap043	近12个⽉发⽣交易的商户数
   tap044	近12个⽉发⽣交易的⼀级⾏业数
   tap045	近12个⽉发⽣交易的⼆级⾏业数
   tap052	近3个⽉最⾼单笔交易⾦额
   tap053	近6个⽉最⾼单笔交易⾦额
   tap054	近12个⽉最⾼单笔交易⾦额

   * @param state
   */
  jiaoyiInfo: state => [
    {
      name: "交易金额",
      n1: state.result_detail.tap031 || "-",// 三个月
      n2: state.result_detail.tap032 || "-",// 六个月
      n3: state.result_detail.tap033 || "-",//十二个月

    },
    {
      name: "交易笔数",
      n1: state.result_detail.tap034 || "-",
      n2: state.result_detail.tap035 || "-",
      n3: state.result_detail.tap036 || "-",

    },
    {
      name: "发⽣交易的商户数",
      n1: state.result_detail.tap037 || "-",
      n2: state.result_detail.tap040 || "-",
      n3: state.result_detail.tap043 || "-",

    },
    {
      name: "发⽣交易的⼀级⾏业数",
      n1: state.result_detail.tap038 || "-",
      n2: state.result_detail.tap041 || "-",
      n3: state.result_detail.tap044 || "-",

    },
    {
      name: "发⽣交易的⼆级⾏业数",
      n1: state.result_detail.tap039 || "-",
      n2: state.result_detail.tap042 || "-",
      n3: state.result_detail.tap045 || "-",

    },
    {
      name: "最⾼单笔交易⾦额",
      n1: state.result_detail.tap052 || "-",
      n2: state.result_detail.tap053 || "-",
      n3: state.result_detail.tap054 || "-",

    }

  ],
  /**
   * tap068	近6个⽉在⽣活购物类交易的⾦额
   tap069	近6个⽉在出⾏旅游类交易的⾦额
   tap070	近6个⽉在⾦融及实物投资类交易的⾦额
   tap071	近6个⽉在教育培训类交易的⾦额
   tap072	近6个⽉在娱乐服务类交易的⾦额
   tap073	近6个⽉在其他交易的⾦额
   tap074	近6个⽉在⽣活购物类交易的笔数
   tap075	近6个⽉在出⾏旅游类交易的笔数
   tap076	近6个⽉在⾦融及实物投资类交易的笔数
   tap077	近6个⽉在教育培训类交易的笔数
   tap078	近6个⽉在娱乐服务类交易的笔数
   tap079	近6个⽉在其他交易的笔数
   tap080	近12个⽉在⽣活购物类交易的⾦额
   tap081	近12个⽉在出⾏旅游类交易的⾦额
   tap082	近12个⽉在⾦融及实物投资类交易的⾦额
   tap083	近12个⽉在教育培训类交易的⾦额
   tap084	近12个⽉在娱乐服务类交易的⾦额
   tap085	近12个⽉在其他交易的⾦额
   tap086	近12个⽉在⽣活购物类交易的笔数
   tap087	近12个⽉在出⾏旅游类交易的笔数
   tap088	近12个⽉在⾦融及实物投资类交易的笔数
   tap089	近12个⽉在教育培训类交易的笔数
   tap090	近12个⽉在娱乐服务类交易的笔数
   tap091	近12个⽉在其他交易的笔数
   tap092	近12个⽉在⼯作⽇⼯作时段交易的⾦额
   tap093	近12个⽉在⼯作⽇⾮⼯作时段交易的⾦额
   tap094	近12个⽉在⾮⼯作⽇交易的⾦额
   tap095	近12个⽉在⼯作⽇⼯作时段交易的笔数
   tap096	近12个⽉在⼯作⽇⾮⼯作时段交易的笔数
   tap097	近12个⽉在⾮⼯作⽇交易的笔数

   * @param state
   * @returns {*[]}
   */
  qitaList: state => [
    {
      name: "生活购物类交易的金额",
      n1: state.result_detail.tap068 || "-",// 六个月
      n2: state.result_detail.tap080 || "-",//十二个月
    },
    {
      name: "出行旅游类交易的金额",
      n1: state.result_detail.tap069 || "-",
      n2: state.result_detail.tap081 || "-",

    },
    {
      name: "金融及实物投资类交易的金额",
      n1: state.result_detail.tap070 || "-",
      n2: state.result_detail.tap082 || "-",

    },
    {
      name: "教育培训类交易的金额",
      n1: state.result_detail.tap071 || "-",
      n2: state.result_detail.tap083 || "-",

    },
    {
      name: "娱乐服务类交易的金额",
      n1: state.result_detail.tap072 || "-",
      n2: state.result_detail.tap084 || "-",

    },
    {
      name: "其他类交易的金额",
      n1: state.result_detail.tap073 || "-",
      n2: state.result_detail.tap085 || "-",

    }

  ],
  /**
   * tap098	近6个⽉使⽤借记卡交易的⾦额
   tap099	近6个⽉使⽤贷记卡交易的⾦额
   tap100	近12个⽉使⽤借记卡交易的⾦额
   tap101	近12个⽉使⽤贷记卡交易的⾦额
   tap102	近6个⽉使⽤借记卡交易的笔数
   tap103	近6个⽉使⽤贷记卡交易的笔数
   tap104	近12个⽉使⽤借记卡交易的笔数
   tap105	近12个⽉使⽤贷记卡交易的笔数
   tap106	近12个⽉使⽤借记卡的最⾼单笔交易⾦额
   tap107	近12个⽉使⽤贷记卡的最⾼单笔交易⾦额

   * @param state
   * @returns {*[]}
   */
  cardList: state => [
    {
      name: "借记卡交易金额",
      n1: state.result_detail.tap098 || "-",// 六个月
      n2: state.result_detail.tap100 || "-",//十二个月
    },
    {
      name: "贷记卡交易金额",
      n1: state.result_detail.tap099 || "-",
      n2: state.result_detail.tap101 || "-",

    },
    {
      name: "借记卡交易笔数",
      n1: state.result_detail.tap102 || "-",
      n2: state.result_detail.tap104 || "-",

    },
    {
      name: "贷记卡交易笔数",
      n1: state.result_detail.tap103 || "-",
      n2: state.result_detail.tap105 || "-",

    },
  ],
//   tap106	近12个⽉使⽤借记卡的最⾼单笔交易⾦额
// tap107	近12个⽉使⽤贷记卡的最⾼单笔交易⾦额

// <!--    tap108	近12个⽉消费⾦额在[0,50]的笔数-->
  // <!--    tap109	近12个⽉消费⾦额在(50,200]的笔数-->
  // <!--    tap110	近12个⽉消费⾦额在(200,1000]的笔数-->
  // <!--    tap111	近12个⽉消费⾦额在(1000,5000]的笔数-->
  // <!--    tap112	近12个⽉消费⾦额在(5000,10000]的笔数-->
  // <!--    tap113	近12个⽉消费⾦额在10000+的笔数-->
  // <!--    tap114	近12个⽉使⽤借记卡在⽣活购物类消费的⾦额-->
  // <!--    tap115	近12个⽉使⽤借记卡在出⾏旅游类消费的⾦额-->
  // <!--    tap116	近12个⽉使⽤借记卡在⾦融及实物投资类消费的⾦额-->
  // <!--    tap117	近12个⽉使⽤借记卡在教育培训类消费的⾦额-->
  // <!--    tap118	近12个⽉使⽤借记卡在娱乐服务类消费的⾦额-->
  // <!--    tap119	近12个⽉使⽤借记卡在其他消费的⾦额-->
  // <!--    tap120	近12个⽉使⽤贷记卡在⽣活购物类消费的⾦额-->
  // <!--    tap121	近12个⽉使⽤贷记卡在出⾏旅游类消费的⾦额-->
  // <!--    tap122	近12个⽉使⽤贷记卡在⾦融及实物投资类消费的⾦额-->
  // <!--    tap123	近12个⽉使⽤贷记卡在教育培训类消费的⾦额-->
  // <!--    tap124	近12个⽉使⽤贷记卡在娱乐服务类消费的⾦额-->
  // <!--    tap125	近12个⽉使⽤贷记卡在其他消费的⾦额-->
  // <!--    tap126	近12个⽉在⽣活购物类交易的⾦额在[0,50]的笔数-->
  // <!--    tap127	近12个⽉在⽣活购物类交易的⾦额在(50,200]的笔数-->
  // <!--    tap128	近12个⽉在⽣活购物类交易的⾦额在(200,1000]的笔数-->
  // <!--    tap129	近12个⽉在⽣活购物类交易的⾦额在(1000,5000]的笔数-->
  // <!--    tap130	近12个⽉在⽣活购物类交易的⾦额在(5000,10000]的笔数-->
  // <!--    tap131	近12个⽉在⽣活购物类交易的⾦额在10000+的笔数-->
  // <!--    tap132	近12个⽉在出⾏旅游类交易的⾦额在[0,50]的笔数-->
  // <!--    tap133	近12个⽉在出⾏旅游类交易的⾦额在(50,200]的笔数-->
  // <!--    tap134	近12个⽉在出⾏旅游类交易的⾦额在(200,1000]的笔数-->
  // <!--    tap135	近12个⽉在出⾏旅游类交易的⾦额在(1000,5000]的笔数-->
  // <!--    tap136	近12个⽉在出⾏旅游类交易的⾦额在(5000,10000]的笔数-->
  // <!--    tap137	近12个⽉在出⾏旅游类交易的⾦额在10000+的笔数-->
  // <!--    tap138	近12个⽉在⾦融及实物投资类交易的⾦额在[0,50]的笔数-->
  // <!--    tap139	近12个⽉在⾦融及实物投资类交易的⾦额在(50,200]的笔数-->
  // <!--    tap140	近12个⽉在⾦融及实物投资类交易的⾦额在(200,1000]的笔数-->
  // <!--    tap141	近12个⽉在⾦融及实物投资类交易的⾦额在(1000,5000]的笔数-->
  // <!--    tap142	近12个⽉在⾦融及实物投资类交易的⾦额在(5000,10000]的笔数-->
  // <!--    tap143	近12个⽉在⾦融及实物投资类交易的⾦额在10000+的笔数-->
  // <!--    tap144	近12个⽉在教育培训类交易的⾦额在[0,50]的笔数-->
  // <!--    tap145	近12个⽉在教育培训类交易的⾦额在(50,200]的笔数-->
  // <!--    tap146	近12个⽉在教育培训类交易的⾦额在(200,1000]的笔数-->
  // <!--    tap147	近12个⽉在教育培训类交易的⾦额在(1000,5000]的笔数-->
  // <!--    tap148	近12个⽉在教育培训类交易的⾦额在(5000,10000]的笔数-->
  // <!--    tap149	近12个⽉在教育培训类交易的⾦额在10000+的笔数-->
  // <!--    tap150	近12个⽉在娱乐服务类交易的⾦额在[0,50]的笔数-->
  // <!--    tap151	近12个⽉在娱乐服务类交易的⾦额在(50,200]的笔数-->
  // <!--    tap152	近12个⽉在娱乐服务类交易的⾦额在(200,1000]的笔数-->
  // <!--    tap153	近12个⽉在娱乐服务类交易的⾦额在(1000,5000]的笔数-->
  // <!--    tap154	近12个⽉在娱乐服务类交易的⾦额在(5000,10000]的笔数-->
  // <!--    tap155	近12个⽉在娱乐服务类交易的⾦额在10000+的笔数-->
  // <!--    tap156	近12个⽉在其他交易的⾦额在[0,50]的笔数-->
  // <!--    tap157	近12个⽉在其他交易的⾦额在(50,200]的笔数-->
  // <!--    tap158	近12个⽉在其他交易的⾦额在(200,1000]的笔数-->
  // <!--    tap159	近12个⽉在其他交易的⾦额在(1000,5000]的笔数-->
  // <!--    tap160	近12个⽉在其他交易的⾦额在(5000,10000]的笔数-->
  // <!--    tap161	近12个⽉在其他交易的⾦额在10000+的笔数-->
  otherInfo: state => [
    { name:"借记卡最高消费",val:state.result_detail.tap106 || "-" },
    { name:"贷记卡最高消费",val:state.result_detail.tap107 || "-" },
    {
      name: "消费⾦额在[0,50]的笔数",
      val: state.result_detail.tap108 || "-",
    },
    {
      name: "消费⾦额在(50,200]的笔数",
      val: state.result_detail.tap109 || "-",
    },
    {
      name: "消费⾦额在(200,1000]的笔数",
      val: state.result_detail.tap110 || "-",
    },
    {
      name: "消费⾦额在(1000,5000]的笔数",
      val: state.result_detail.tap111 || "-",
    },
    {
      name: "消费⾦额在(5000,10000]的笔数",
      val: state.result_detail.tap112 || "-",
    },
    {
      name: "消费⾦额在10000+的笔数",
      val: state.result_detail.tap113 || "-",
    },
    {
      name:"借记卡在⽣活购物类消费的⾦额",
      val:state.result_detail.tap114 || "-"
    },
    {
      name:"借记卡在出⾏旅游类消费的⾦额",
      val:state.result_detail.tap115 || "-"
    },
    {
      name:"借记卡在⾦融及实物投资类消费的⾦额",
      val:state.result_detail.tap116 || "-"
    },
    {
      name:"借记卡在教育培训类消费的⾦额",
      val:state.result_detail.tap117 ||"-"
    },
    {
      name:"借记卡在娱乐服务类消费的⾦额",
      val:state.result_detail.tap118 || "-"
    },
    {
      name:"借记卡在其他消费的⾦额",
      val:state.result_detail.tap119 || "-"
    },
    {
      name:"贷记卡在⽣活购物类消费的⾦额",
      val:state.result_detail.tap120 || "-"
    },
    {
      name:"贷记卡在出⾏旅游类消费的⾦额",
      val:state.result_detail.tap121 || "-"
    },
    {
      name:"贷记卡在⾦融及实物投资类消费的⾦额",
      val:state.result_detail.tap122 || "-"
    },
    {
      name:"贷记卡在教育培训类消费的⾦额",
      val:state.result_detail.tap123 || "-"
    },
    {
      name:"贷记卡在娱乐服务类消费的⾦额",
      val:state.result_detail.tap124 || "-"
    },
    {
      name:"贷记卡在其他消费的⾦额",
      val:state.result_detail.tap125 || "-"
    },
    {
      name:"⽣活购物类交易的⾦额在[0,50]的笔数",
      val:state.result_detail.tap126 || "-"
    },
    {
      name:"⽣活购物类交易的⾦额在(50,200]的笔数",
      val:state.result_detail.tap127 || "-"
    },
    {
      name:"⽣活购物类交易的⾦额在(200,1000]的笔数",
      val:state.result_detail.tap128 || "-"
    },
    {
      name:"⽣活购物类交易的⾦额在(1000,5000]的笔数",
      val:state.result_detail.tap129 || "-"
    },
    {
      name:"⽣活购物类交易的⾦额在(5000,10000]的笔数",
      val:state.result_detail.tap130 || "-"
    },
    {
      name:"⽣活购物类交易的⾦额在10000+的笔数",
      val:state.result_detail.tap131 || "-"
    },
    {
      name:"出⾏旅游类交易的⾦额在[0,50]的笔数",
      val:state.result_detail.tap132 || "-"

    },
    {
      name:"出⾏旅游类交易的⾦额在(50,200]的笔数",
      val:state.result_detail.tap133 || "-"
    },
    {
      name:"出⾏旅游类交易的⾦额在(200,1000]的笔数",
      val:state.result_detail.tap134 || "-"
    },
    {
      name:"出⾏旅游类交易的⾦额在(1000,5000]的笔数",
      val:state.result_detail.tap135 || "-"
    },
    {
      name:"出⾏旅游类交易的⾦额在(5000,10000]的笔数",
      val:state.result_detail.tap136 || "-"
    },
    {
      name:"出⾏旅游类交易的⾦额在10000+的笔数",
      val:state.result_detail.tap137 || "-"
    },
    {
      name:"⾦融及实物投资类交易的⾦额在[0,50]的笔数",
      val:state.result_detail.tap138 || "-"
    },
    {
      name:"⾦融及实物投资类交易的⾦额在(50,200]的笔数",
      val:state.result_detail.tap139 || "-"
    },
    {
      name:"⾦融及实物投资类交易的⾦额在(200,1000]的笔数",
      val:state.result_detail.tap140 || "-"
    },
    {
      name:"⾦融及实物投资类交易的⾦额在(1000,5000]的笔数",
      val:state.result_detail.tap141 || "-"
    },
    {
      name:"⾦融及实物投资类交易的⾦额在(5000,10000]的笔数",
      val:state.result_detail.tap142 || "-"
    },
    {
      name:"⾦融及实物投资类交易的⾦额在10000+的笔数",
      val:state.result_detail.tap143 || "-"
    },
    {
      name:"教育培训类交易的⾦额在[0,50]的笔数",
      val:state.result_detail.tap144 || "-"
    },
    {
      name:"教育培训类交易的⾦额在(50,200]的笔数",
      val:state.result_detail.tap145 || "-"
    },
    {
      name:"教育培训类交易的⾦额在(200,1000]的笔数",
      val:state.result_detail.tap146 || "-"
    },
    {
      name:"教育培训类交易的⾦额在(1000,5000]的笔数",
      val:state.result_detail.tap147 || "-"
    },
    {
      name:"教育培训类交易的⾦额在(5000,10000]的笔数",
      val:state.result_detail.tap148 || "-"
    },
    {
      name:"教育培训类交易的⾦额在10000+的笔数",
      val:state.result_detail.tap149 || "-"
    },
    {
      name:"娱乐服务类交易的⾦额在[0,50]的笔数",
      val:state.result_detail.tap150 || "-"
    },
    {
      name:"娱乐服务类交易的⾦额在(50,200]的笔数",
      val:state.result_detail.tap151 || "-"

    },
    {
      name:"娱乐服务类交易的⾦额在(200,1000]的笔数",
      val:state.result_detail.tap152 || "-"
    },
    {
      name:"娱乐服务类交易的⾦额在(1000,5000]的笔数",
      val:state.result_detail.tap153 || "-"
    },
    {
      name:"娱乐服务类交易的⾦额在(5000,10000]的笔数",
      val:state.result_detail.tap154 || "-"
    },
    {
      name:"娱乐服务类交易的⾦额在10000+的笔数",
      val:state.result_detail.tap155 || "-"
    },
    {
      name:"其他类交易的⾦额在[0,50]的笔数",
      val:state.result_detail.tap156 || "-"
    },
    {
      name:"其他类交易的⾦额在(50,200]的笔数",
      val:state.result_detail.tap157 || "-"
    },
    {
      name:"其他类交易的⾦额在(200,1000]的笔数",
      val:state.result_detail.tap158 || "-"
    },
    {
      name:"其他类交易的⾦额在(1000,5000]的笔数",
      val:state.result_detail.tap159 || "-"
    },
    {
      name:"其他类交易的⾦额在(5000,10000]的笔数",
      val:state.result_detail.tap160 || "-"
    },
    {
      name:"其他类交易的⾦额在10000+的笔数",
      val:state.result_detail.tap161 || "-"
    }
  ],


};

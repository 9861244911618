import service from "../../api";
import stateData from "./state";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setOperator(state, data = {}) {
      for (let key in data) {
        state[key] = data[key];
      }

    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    // 全景雷达信息
    getOperator({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("setOperator", {
        ...stateData
      });
      commit("userReport/setLoading", true, {
        root: true
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'OPERATOR')
        .then(res => {
          commit("setOperator", res.data || {});
          commit("setProvider", res.provider || "");
          commit("setRefreshDate", res.date),
            commit("userReport/setLoading", false, {
              root: true
            });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters
};

import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setExplored (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider (state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate (state, date) {
      state.date = date;
    }
  },
  actions: {
    getExplored ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setExplored", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'EXPLORED')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setExplored", {
            provider,
            result_detail: data['EXPLORED'].data,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    apply: state => {
      let data = state.result_detail.apply_report_detail
      if (!data) {
        data = {}
      }
      return [{ label: '申请准入分', val: data.A22160001 }, 
      { label: '申请准入置信度', val: data.A22160002 },
      { label: '申请命中机构数', val: data.A22160003 }, 
      { label: '申请命中消金类机构数', val: data.A22160004 },
      { label: '申请命中网络贷款类机构数', val: data.A22160005 }, 
      { label: '机构总查询次数', val: data.A22160006 },
      { label: '最近一次查询时间', val: data.A22160007 }, 
      { label: '近1个月机构总查询笔数', val: data.A22160008 },
      { label: '近3个月机构总查询笔数', val: data.A22160009 }, 
      { label: '近6个月机构总查询笔数', val: data.A22160010 }]
    },
    behavior: state => {
      let data = state.result_detail.apply_report_detail
      if (!data) {
        data = {}
      }
      return [{ label: '贷款行为分', val: data.B22170001 }, { label: '近1个月贷款笔数', val: data.B22170002 },
      { label: '近3个月贷款笔数', val: data.B22170003 }, { label: '近6个月贷款笔数', val: data.B22170004 },
      { label: '近12个月贷款笔数', val: data.B22170005 }, { label: '近24个月贷款笔数', val: data.B22170006 },
      { label: '近1个月贷款总金额', val: data.B22170007 }, { label: '近3个月贷款总金额', val: data.B22170008 },
      { label: '近6个月贷款总金额', val: data.B22170009 }, { label: '近12个月贷款总金额', val: data.B22170010 },
      { label: '近24个月贷款总金额', val: data.B22170011 }, { label: '近12个月贷款金额在1k及以下的笔数', val: data.B22170012 },
      { label: '近12个月贷款金额在1k-3k的笔数', val: data.B22170013 }, { label: '近12个月贷款金额在3k-10k的笔数', val: data.B22170014 },
      { label: '近12个月贷款金额在1w以上的笔数', val: data.B22170015 }, { label: '近1个月贷款机构数', val: data.B22170016 },
      { label: '近3个月贷款机构数', val: data.B22170017 }, { label: '近6个月贷款机构数', val: data.B22170018 },
      { label: '近12个月贷款机构数', val: data.B22170019 }, { label: '近24个月贷款机构数', val: data.B22170020 },
      { label: '近12个月消金类贷款机构数', val: data.B22170021 }, { label: '近24个月消金类贷款机构数', val: data.B22170022 },
      { label: '近12个月网贷类贷款机构数', val: data.B22170023 }, { label: '近24个月网贷类贷款机构数', val: data.B22170024 },
      { label: '近6个月M0+逾期贷款笔数', val: data.B22170025 }, { label: '近12个月M0+逾期贷款笔数', val: data.B22170026 },
      { label: '近24个月M0+逾期贷款笔数', val: data.B22170027 }, { label: '近6个月M1+逾期贷款笔数', val: data.B22170028 },
      { label: '近12个月M1+逾期贷款笔数', val: data.B22170029 }, { label: '近24个月M1+逾期贷款笔数', val: data.B22170030 },
      { label: '近6个月累计逾期金额', val: data.B22170031 }, { label: '近12个月累计逾期金额', val: data.B22170032 },
      { label: '近24个月累计逾期金额', val: data.B22170033 }, { label: '正常还款订单数占贷款总订单数比例', val: data.B22170034 },
      { label: '近1个月失败扣款笔数', val: data.B22170035 }, { label: '近3个月失败扣款笔数', val: data.B22170036 },
      { label: '近6个月失败扣款笔数', val: data.B22170037 }, { label: '近12个月失败扣款笔数', val: data.B22170038 },
      { label: '近24个月失败扣款笔数', val: data.B22170039 }, { label: '近1个月履约贷款总金额', val: data.B22170040 },
      { label: '近3个月履约贷款总金额', val: data.B22170041 }, { label: '近6个月履约贷款总金额', val: data.B22170042 },
      { label: '近12个月履约贷款总金额', val: data.B22170043 }, { label: '近24个月履约贷款总金额', val: data.B22170044 },
      { label: '近1个月履约贷款次数', val: data.B22170045 }, { label: '近3个月履约贷款次数', val: data.B22170046 },
      { label: '近6个月履约贷款次数', val: data.B22170047 }, { label: '近12个月履约贷款次数', val: data.B22170048 },
      { label: '近24个月履约贷款次数', val: data.B22170049 }, { label: '最近一次履约距今天数', val: data.B22170050 },
      { label: '贷款行为置信度', val: data.B22170051 }, { label: '贷款已结清订单数', val: data.B22170052 },
      { label: '信用贷款时长', val: data.B22170053 }, { label: '最近一次贷款放款时间', val: data.B22170054 }]
    },
    current: state => {
      let data = state.result_detail.apply_report_detail
      if (!data) {
        data = {}
      }
      return [{ label: '网贷授信额度', val: data.C22180001 }, { label: '网贷额度置信度', val: data.C22180002 },
      { label: '网络贷款类机构数', val: data.C22180003 }, { label: '网络贷款类产品数', val: data.C22180004 },
      { label: '网络贷款机构最大授信额度', val: data.C22180005 }, { label: '网络贷款机构平均授信额度', val: data.C22180006 },
      { label: '消金贷款类机构数', val: data.C22180007 }, { label: '消金贷款类产品数', val: data.C22180008 },
      { label: '消金贷款类机构最大授信额度', val: data.C22180009 }, { label: '消金贷款类机构平均授信额度', val: data.C22180010 },
      { label: '消金建议授信额度', val: data.C22180011 }, { label: '消金额度置信度', val: data.C22180012 }]
    }
  }
};
